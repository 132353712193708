<template>
    <div class="w-full flex flex-col">
        <ConfirmationModal codeConfirmation @success="deleteSuccess" />

        <ModalAdd
            :visible="modalAddVisible"
            :employeeUuid="employeeUuid"
            @refresh="getAll"
            @close="modalAddVisible = false"
        />

        <div class="flex card items-center justify-between border-b border-gray-300 pb-4">
            {{ $t('client_details.emergency_contacts') }}
            <el-button type="primary" @click="showModalAdd">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <div class="flex flex-col items-center w-full">
            <Person v-for="person in emergencyContactsData" :key="person.id" :person="person" />
        </div>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
import Api from './emergency_contacts.api';

export default {
    components: {
        ModalAdd: () => import(/* webpackChunkName: "ClientDetails/EmergencyContactsModalAdd" */ './EmergencyContactsModalAdd'),
        Person:   () => import(/* webpackChunkName: "ClientDetails/EmergencyContactsPerson" */ './EmergencyContactsPerson'),
    },

    data() {
        return {
            employeeUuid:          this.$route.params.uuid,
            noDataInformation:     false,
            emergencyContactsData: [],
            modalAddVisible:       false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'emergency_contacts');
        this.$wait.start('loading.initial_emergency_contacts');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.emergency_contacts');

            try {
                this.emergencyContactsData = await Api.getAll(this.employeeUuid);
                this.noDataInformation = this.emergencyContactsData.length;
            } finally {
                this.$wait.end('loading.emergency_contacts');
                this.$wait.end('loading.initial_emergency_contacts');
            }
        },

        showModalAdd() {
            this.modalAddVisible = true;
        },

        deleteSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
